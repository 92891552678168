<ng-template #optonsAcciones let-offcanvas>
    <div class="offcanvas-header">
        <div class=" w-100">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 text-end pt-2">
                    <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
                </div>
            </div>

        </div>
    </div>
    <div class="offcanvas-body">
        <div class="row">
            <div class="col-12 col-md-6 offset-md-3 ">
                <app-validate-phone *ngIf="activeValidateTlf" [selected]="activeValidateTlf" [config]="dataValidateTlf" (close)="closeValidateTlf($event)" (setear)="setValidateTlf($event)">
                </app-validate-phone>
            </div>
        </div>

    </div>

</ng-template>

<div class="container">
    <div class="row">
        <div class="col-12 ">

            <div class="mt-md-3 ms-auto me-auto p-md-auto">
                <ng-container *ngIf="step == 1">
                    <h5 class="mb-4 text-md-center mt-3 text-secondary">Documento de Identidad

                        <span (click)="reloadLS()"  class="text-primary ms-2">
                            <i class="fas fa-tablet-alt"></i>
                        </span>
                        
                    </h5>

                    <div class=" box pt-2 pb-2 p-1  pt-md-4 pb-md-4 p-3 fz-14 ">

                        <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors">
                            <ng-container *ngFor="let item of errors">

                                <div class="d-inline-flex w-10">
                                </div>
                                <div class="d-inline-flex w-90">
                                    <i class="fas fa-info-circle pe-2 "></i>
                                    <span>{{item?.msg }}  </span>
                                </div>
                            </ng-container>
                        </div>

                        <form autocomplete="nope" novalidate [formGroup]="form" (ngSubmit)="next(2)" class="text-start">
                            <div class="row mt-3">
                                <div class="form-group  col-12">
                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.type_identity.invalid && form.controls.type_identity.dirty}" class="form-select" id="type_identity" formControlName="type_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of type_identity"  [value]="item.code">{{item.name}}</option>
                    </select>

                                        <label for="type_identity">Tipo de Documento</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('type_identity').errors">
                                        <div *ngIf="form.get('type_identity').errors['required'] && !form.get('type_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>

                                <div class="form-group  col-3 mt-3">

                                    <div class="form-floating">
                                        <select [ngClass]="{'error': form.controls.code_identity.invalid && form.controls.code_identity.dirty}" class="form-select" id="code_identity" formControlName="code_identity" aria-label="Floating label select example">
                        <option  value="">Selecione</option>
                        <option *ngFor="let item of code_identity"  [value]="item.code">{{item.name}}</option>
                                 </select>



                                        <label for="code_identity">Tipo</label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('code_identity').errors">
                                        <div *ngIf="form.get('code_identity').errors['required'] && !form.get('code_identity').pristine">
                                            Campo requerido
                                        </div>
                                    </div>
                                </div>
                                <div class="form-group  col-9  mt-3">

                                    <div class="form-floating ">
                                        <input type="text" [ngClass]="{'error': form.controls.identity.invalid && form.controls.identity.dirty}" class="form-control text-capitalize" id="identity" formControlName="identity">
                                        <label for="identity" class=" label font-weight-normal" style="word-wrap: break-word;">
                      <span >Documento </span>
                 </label>
                                    </div>

                                    <div class="form-control-feedback help-block" *ngIf="form.get('identity').errors">
                                        <div *ngIf="form.get('identity').errors['required'] && !form.get('identity').pristine">
                                            Campo requerido
                                        </div>
                                        <div *ngIf="form.get('identity').hasError('minlength')  && !form.get('identity').pristine">
                                            Mínimo 7 caracteres
                                        </div>

                                        <div *ngIf="form.get('identity').hasError('maxlength')  && !form.get('identity').pristine">
                                            Máximo 8 caracteres
                                        </div>

                                    </div>
                                </div>

                                <br>
                                <div class=" col-12 mt-4 mb-2 text-end">
                                    <div class="d-grid gap-2">


                                        <button type="submit" [disabled]="!form.valid || submit_disabled" class="btn  btn-primary ">
                                                    <span >Continuar</span>
                                                    <span *ngIf="submit_disabled">
                                                      <i class="fa  fa-spinner fa-spin ms-2"></i>
                                                    </span>
                                                  </button>
                                    </div>

                                </div>

                            </div>
                        </form>

                    </div>
                </ng-container>

                <ng-container *ngIf="step == 2 && !loadingSubmit">

                    <div class="pb-3">
                        <button type="button" (click)="step= 1" class="btn-close float-start " aria-label="Close"></button>
                    </div>

                    <div class="h5 text-md-center mb-4 mt-2 text-secondary">
                        Por favor valida tu información de contacto.
                    </div>

                    <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors  box">
                        <ng-container *ngFor="let item of errors">

                            <div class="d-inline-flex w-10">
                            </div>
                            <div class="d-inline-flex w-90">
                                <i class="fas fa-info-circle pe-2 "></i>
                                <span>{{item?.msg }}  </span>
                            </div>
                        </ng-container>
                    </div>

                    <div class=" box p-3 fz-14 " *ngFor="let item of contacts">

                        <div class="pb-3">
                            <div class="h5"> Teléfono
                            </div>
                            <button *ngIf="!env?.production" (click)="re_pass_resp = true;dataValidateTlf=item?.contact;setFormBase()" [disabled]="submit_disabled" class="btn btn-secondary float-end">
                         No validar
                     <span *ngIf="submit_disabled">
                         <i class="fa  fa-spinner fa-spin ms-2"></i>
                       </span>
                 </button>


                            <button (click)="validateContact(optonsAcciones,item)" [disabled]="submit_disabled" class="btn btn-primary float-end me-2">
                    Validar
                    <span *ngIf="submit_disabled">
                        <i class="fa  fa-spinner fa-spin ms-2"></i>
                      </span>
                </button>


                        </div>

                        <p class="card-text h6"><span class="text-secondary">{{getHiddenPhone(item?.contact)}}</span></p>
                    </div>
                </ng-container>

                <ng-container *ngIf="step == 4">

                    <div class="pb-3">
                        <button type="button" (click)="reset()" class="btn-close float-start " aria-label="Close"></button>
                    </div>
                    <div class="mt-md-3 ms-auto me-auto p-3 p-md-auto">
                        <div class="  box pt-2 pb-2 p-1  pt-md-4 pb-md-4 p-3 fz-14 ">
                            <h5 class="mb-4" *ngIf="!re_pass_resp">Iniciar sesión</h5>
                            <h5 class="mb-4" *ngIf="re_pass_resp">Creación de usuario</h5>


                            <!-- *ngIf="errors?.length > 0 && errors_type == 'v'" -->
                            <div *ngIf="errors?.length > 0" class="mb-4 text-start p-3 box-errors">
                                <ng-container *ngFor="let item of errors">

                                    <div class="d-inline-flex w-10">
                                    </div>
                                    <div class="d-inline-flex w-90">
                                        <i class="fas fa-info-circle pe-2 "></i>

                                        <span>{{item?.msg }}  </span>

                                    </div>
                                </ng-container>
                            </div>

                            <form novalidate [formGroup]="formValores" (ngSubmit)="submit()" class="text-start">
                                <div class="row">
                                    <div class="form-group col-12  mb-3">
                                        <!-- <div class="form-floating ">
                                            <input [readonly]="true" type="text" [ngClass]="{'error': formValores.controls.username.invalid && formValores.controls.username.dirty}" class="form-control" id="username" formControlName="username" placeholder="user1">
                                            <label for="username" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span >Username</span>
                                      </label>
                                        </div> -->
                                        <div class="form-control-feedback help-block" *ngIf="formValores.get('username').errors">
                                            <div *ngIf="formValores.get('username').errors['required'] && !formValores.get('username').pristine">
                                                Campo requerido
                                            </div>
                                        </div>
                                    </div>


                                    <div class="form-group col-12 mb-3">
                                        <div class="form-floating ">
                                            <input type="password" [ngClass]="{'error': formValores.controls.password.invalid && formValores.controls.password.dirty}" class="form-control" id="password" formControlName="password" placeholder="name@example.com">
                                            <label for="password" class=" label font-weight-normal" style="word-wrap: break-word;">
                                        <span *ngIf=" !dataValidateTlf" >Password</span>
                                        <span  *ngIf=" dataValidateTlf">Nuevo Password</span>
                                      </label>
                                        </div>
                                        <div class="form-control-feedback help-block" *ngIf="formValores.get('password').errors">
                                            <div *ngIf="formValores.get('password').errors['required'] && !formValores.get('password').pristine">
                                                Campo requerido
                                            </div>
                                            <div *ngIf="formValores.get('password').hasError('minlength')  && !formValores.get('password').pristine">
                                                Mínimo {{passwordMinLength}} caracteres
                                            </div>

                                            <div *ngIf="formValores.get('password').hasError('maxlength')  && !formValores.get('password').pristine">
                                                Máximo {{passwordMaxLength}} caracteres
                                            </div>

                                        </div>
                                    </div>
                                    <br>
                                    <div class=" col-12 mt-2 mb-2">
                                        <button type="submit" [disabled]="!formValores.valid || submit_disabled" class="btn btn-block btn-primary ">
                                                                <span >Continuar</span>
                                                                <span *ngIf="submit_disabled">
                                                                  <i class="fa  fa-spinner fa-spin ms-2"></i>
                                                                </span>
                                                              </button>
                                    </div>

                                </div>
                            </form>

                            <div class="text-end text-primary mt-2 mb-2 cursor-pounter" (click)="re_pass = true;step = 2">Restablecer contraseña</div>
                        </div>


                    </div>
                </ng-container>

                <ng-container *ngIf="loadingSubmit ">
                    <div class="w-100 text-center">
                        <img src="./assets/public/images/loading.gif  " alt=" ">
                    </div>
                </ng-container>

            </div>
        </div>
    </div>
</div>