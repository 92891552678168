<!-- 
<div *ngIf="!isConnected" [ngClass]="{'toast-danger': status != 'ONLINE','toast-success': status == 'ONLINE'}" class="ak-alert toast botton-left    {{!isConnected?'animate__animated animate__fadeIn animate__faster ':'' }}">

    <div class="toast-body">
        <span *ngIf="status == 'ONLINE'">
            Conexión restablecida
        </span>
        <span *ngIf="status == 'OFFLINE'">
            Se está intentando establecer la conexión...<br> Revise su conexión a la red
        </span>
    </div>
</div> -->

<!-- <div *ngIf="status == 'OFFLINE'" class="capa-menu"></div> -->

<div class="pb-5 mb-3" *ngIf="!biometria">
    <app-navbarauth *ngIf="showNav "></app-navbarauth>

    <div class="col-12 col-md-6 offset-md-3 pb-4">
        <router-outlet></router-outlet>
    </div>
</div>


<div *ngIf="isAuth && activeMenu && !biometria" class="d-block d-md-none">
    <div class="md-pic-edit">

        <mbsc-optionlist [options]="settings">


            <mbsc-option-item data-text="Activos">
                <div (click)="setOptionMenu(1);closeOffcanvas()" [routerLink]="['/']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 1}">
                        <i class="  fas fa-home"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Inicio
                        </div>
                    </div>
                </div>
            </mbsc-option-item>




            <mbsc-option-item data-text="Consolidado">
                <div (click)="setOptionMenu(3);closeOffcanvas()" [routerLink]="['/financial']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 3}">
                        <i class="fas fa-piggy-bank"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Mis compras
                        </div>
                    </div>

                </div>
            </mbsc-option-item>


            <mbsc-option-item data-text="Consolidado">
                <div (click)="setOptionMenu(4);closeOffcanvas()" [routerLink]="['/payments']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 4}">
                        <i class="fas fa-money-bill"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Mis pagos
                        </div>
                    </div>

                </div>
            </mbsc-option-item>
            <!-- 
            <mbsc-option-item data-text="Ayuda">
                <div (click)="setOptionMenu(5);closeOffcanvas()" [routerLink]="['/']">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 5}">
                        <i class="fas fa-life-ring"></i> </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-secondary fz-12 ">
                            Ayuda
                        </div>
                    </div>

                </div>
            </mbsc-option-item> -->

            <!-- <mbsc-option-item data-text="Consolidado">
                <div (click)="setOptionMenu(2);closeOffcanvas()">
                    <div class="icon-menu-mbs" [ngClass]="{'active': menuOption == 2}">
                        <i class="fas fa-terminal"></i>
                    </div>
                    <div class="text-center active-cursor">
                        <div class="active-cursor text-center text-white fz-12 ">
                            Opciones
                        </div>
                    </div>

                </div>
            </mbsc-option-item> -->



        </mbsc-optionlist>

    </div>
</div>

<div class="">

    <a (click)="gotoWhatsapp('+584128809225')" target="_blank">
        <img id="appear" class="whatsapp" [src]="sanitize('../assets/public/images/descarga.jpeg')" alt="IconoWhatsApp" />
    </a>
</div>