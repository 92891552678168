<ng-template #optonsAcciones let-offcanvas>

    <div class="offcanvas-header">
        <div class="d-flex w-100 mt-2">

            <div class="text-start ">
                <div class="fw-bold fz-18">
                    Infomación del pago

                </div>
                <div class="small">
                    {{payment?.payment?.register_payment_date | date:'d MMM, yyyy':'es'}}

                </div>
            </div>

            <div class="text-end ms-auto">
                <button type="button" class="btn-close" aria-label="Close" (click)="offcanvas.dismiss('Cross click')"></button>
            </div>

        </div>

    </div>

    <div class="offcanvas-body">
        <div class="" *ngIf="!payment?.installMent &&  payment?.installMents_ref?.installMent?.invoice?.identifier">
            {{payment?.installMents_ref?.installMent?.invoice?.identifier}} (

            <span class="small text-secondary">
                {{ payment?.installMents_ref?.installMent?.order
                    < 1 ? 'Inicial': 'Cuota '+payment?.installMents_ref?.installMent?.order}}    
            </span>)
        </div>


        <div class="" *ngIf="payment?.installMent &&  payment?.installMent[0]">
            {{payment?.installMent[0]?.installMent?.invoice?.identifier}} (
            <span class="small text-secondary">
                {{ payment?.installMent[0]?.installMent?.order
                    < 1 ? 'Inicial': 'Cuota '+payment?.installMent[0]?.installMent?.order}}    
            </span>)
        </div>




        <hr class="pt-0  pb-4">

        <div class="text-secondary ">

            <ng-container>

                <div class="mb-3">
                    <div class=" mb-2">

                        <span class="badge text-bg-warning " *ngIf="!payment?.payment?.reconciled_payment && payment?.payment?.status">Pago por conciliar</span>
                        <span class="badge text-bg-danger" *ngIf="!payment?.payment?.status">Pago rechazado</span>
                        <span class="badge text-bg-success" *ngIf="payment?.payment?.reconciled_payment && payment?.payment?.status">Pago aprobado</span>

                    </div>
                    <div class="text-dark fz-12" *ngIf="payment?.payment?.responseAutoConfirm?.descripcion">
                        {{payment?.payment?.responseAutoConfirm?.descripcion}}
                    </div>
                </div>


                <div class="mb-2 mt-4 text-dark">

                    Cuenta destino

                </div>

                <div class="d-flex ">
                    <div>
                        <div>
                            {{payment?.payment?.account_payment?.bank?.name}}
                        </div>

                        <div class="fz-12" *ngIf="payment?.payment?.account_payment">

                            {{payment?.payment?.account_payment?.payment_method?.name}} /



                            <span class=" " *ngIf="payment?.payment?.typeTransaction">
                            {{payment?.payment?.account_payment?.number}}
                        </span>

                            <span class="" *ngIf="payment?.payment?.account_payment?.phone">
                            {{payment?.payment?.account_payment?.phone}}
                        </span>

                        </div>
                        <div class="fz-12" *ngIf="!payment?.payment?.account_payment">
                            Pago inicial
                        </div>


                    </div>
                    <div class=" ms-auto text-dark">
                        <span class=" fw-bold">{{payment?.payment?.counterValue}} {{payment?.payment?.currency?.name}}</span> /
                        <span class=" fw-bold">{{payment?.payment?.amount}} USD</span>
                    </div>
                </div>

                <div class="mb-3 mt-4 text-dark">

                    Detalle

                </div>

                <div class="d-flex mt-2" *ngIf="payment?.payment?.paymentMethodId">
                    <div>
                        Referencia
                    </div>
                    <div class="ms-auto">
                        #{{payment?.payment?.paymentMethodId}}
                    </div>
                </div>

                <div class="mt-3" *ngIf=" payment?.payment?.responseAutoConfirm?.voucher">
                    <div class="col-12 ">
                        <div class="voucher p-2 text-dark" [innerHTML]=" payment?.payment?.responseAutoConfirm?.voucher">

                        </div>

                    </div>
                </div>

                <!-- <pre>{{ payment?.payment | json }}</pre> -->


                <!--
        <div class="d-flex mt-2">
            <div>
                Identificación
            </div>
            <div class=" ms-auto">
                V-19163767
            </div>
        </div>-->

            </ng-container>


            <div class="d-flex mt-2" *ngIf="payment?.payment?.description">
                <div>
                    Descripción
                </div>
                <div class="ms-auto ">

                    {{payment?.payment?.description}}
                </div>
            </div>

            <div class="mb-3 mt-4 text-dark" *ngIf="payment?.payment?.autodescription?.voucher">
                Voucher
            </div>

            <div class="row" *ngIf="payment?.payment?.autodescription?.voucher">
                <div class="col-12 col-md-6">
                    <div class="voucher p-2 text-dark" [innerHTML]="payment?.payment?.autodescription?.voucher?.linea">

                    </div>

                </div>
            </div>

        </div>

    </div>


</ng-template>


<div class="container mt-2  mt-md-4">

    <ng-container>
        <div class="text-start mt-4 ">
            <h3 class="text-dark fw-bold mt-2  mb-0">
                Mis pagos
            </h3>
        </div>
        <ng-container>


            <!-- <div class=" d-flex w-100 mt-4">
                <div class=" ">
                    <div class="text-secondary small">
                        Pagado a la fecha
                    </div>
                    <div class="fw-bold fz-18 ">

                        {{invoiceCredit.totalPaymentConcile }} USD

                    </div>

                </div>
                <div class="ms-auto text-end">
                    <div class=" ">
                        <div class="text-secondary small">
                            Pendiente por pagar
                        </div>
                        <div class="fw-bold fz-18 ">
                            {{invoiceCredit.dueBalance}} USD


                        </div>

                    </div>


                </div>

            </div> -->




            <hr class="pt-0 mt-4 pb-4">

            <div class="card  cmt2 mt-3 " *ngFor="let item of resp_paymentsCredits" (click)="openview(optonsAcciones,item)">
                <div class="card-body">

                    <div class="d-flex w-100 bd-highlight">

                        <div class="cupo-i  bd-highlight">
                            <i [ngClass]="{'text-secondary': !item?.payment?.reconciled_payment && item?.payment?.status,
                                           'text-success': item?.payment?.reconciled_payment && item?.payment?.status,
                                           'text-danger': !item?.payment?.status }" class="far fa-check-circle "></i>
                        </div>

                        <div class="flex-grow-1 bd-highlight">
                            <div class="d-flex w-100">
                                <div>
                                    <div class="ms-2" *ngIf="!item?.payment?.reconciled_payment && item?.payment?.status">
                                        <span class="small"> Procesando:</span>
                                        <span class="fw-bold small">{{item?.payment?.date | date:'d MMM, yyyy':'es'}}</span>
                                    </div>
                                    <div class="ms-2" *ngIf="item?.payment?.reconciled_payment && item?.payment?.status">
                                        <span class="small"> Pagado:</span>
                                        <span class="fw-bold small">{{item?.payment?.reconciled_payment_date | date:'d MMM, yyyy':'es'}}</span>
                                    </div>

                                    <div class="ms-2" *ngIf="!item?.payment?.status">
                                        <span class="small"> {{item?.payment?.typeTransaction == 'AUTO'?'Transacción fallida': 'Anulado'}}  :</span>
                                        <span class="fw-bold small">{{item?.payment?.date | date:'d MMM, yyyy':'es'}}</span>
                                    </div>


                                </div>
                                <div class="text-end ms-auto">
                                    <span class="fw-bold small"> {{item?.payment?.counterValue}} {{item?.payment?.currency?.name}} </span>
                                </div>
                            </div>

                            <div class="d-flex w-100">


                                <div class="ms-2 text-secondary small">
                                    <span>{{item?.payment?.account_payment?.bank?.name || 'Pago Inicial'}} </span>

                                </div>

                                <!-- <div class="text-end ms-auto">
                                    <span class=" small text-success" [ngClass]="{'text-success': item?.payment?.typeTransaction == 'AUTO','text-secondary': item?.payment?.typeTransaction != 'AUTO'}"> {{item?.payment?.typeTransaction == 'AUTO'? 'Auto':'Manual'}}</span>
                                </div> -->
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </ng-container>

        <!-- <pre>{{ payment | json }}</pre> -->


        <!-- <pre>{{ resp_paymentsCredits | json }}</pre> -->



    </ng-container>

</div>

<ng-container *ngIf="loading ">
    <div class="w-100 text-center ">
        <img src="./assets/public/images/loading.gif " alt=" ">
    </div>
</ng-container>