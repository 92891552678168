<div class="div-nav">
    <nav class="navbar ak-nav-a ">

        <div class="d-flex col-12 col-md-6 offset-md-3 ">
            <div>
                         <img [routerLink]="['/']" class="img-style pt-2" [src]="sanitize(banner)">
            </div>
            <div class="ms-auto text-end">
                <div *ngIf="isAuth" #myDrop="ngbDropdown" ngbDropdown display="dynamic" placement="bottom-right">

                    <div style="cursor: pointer" ngbDropdownToggle id="navbarDropdown3" class=" navbar-brand-profile ak-vertical-center">

                        <!-- <div class="ak-abatar ak-dinamic-color-abatar  ">JS</div> -->
                        <div class="ak-abatar ak-dinamic-color-abatar  ">
                            <i class="fa fa-bars ak-text-color-6 "></i>
                        </div>
                    </div>

                    <div style="width: 270px;" ngbDropdownMenu aria-labelledby="navbarDropdown3" class="dropdown-menu">
                        <div class="col-12 pb-3  pt-2   text-center">
                            <div style="font-size:.82rem" class=" container w-100 mt-2">

                                <div class=" w-100 ">

                                    <div class=" ms-auto me-auto mb-2  ak-abatar ak-dinamic-color-abatar  menu">
                                        <i class="fa fa-user ak-text-color-6 fa-2x"></i>
                                    </div>

                                    <div class="ms-auto me-auto fw-bold mb-2" style="font-size: 16px;">

                                        <div class=" text-capitalize ">
                                            <span> {{sessionUser?.name}}</span>
                                            <span> {{sessionUser?.lastName}}</span>
                                        </div>
                                        <!-- 
                                        <span *ngIf="socketService.statusConnet" class="text-success fz-14 me-1">Online</span>
                                        <span *ngIf="!socketService.statusConnet" class="text-danger fz-14 me-1">Offline</span> -->

                                    </div>

                                    <!-- <div class="mt-2 mb-2"> {{sessionUser?.username}}</div> -->
                                </div>

                                <div class="mt-2">
                                    <a href="javascript:void(0)" [routerLink]="['/']" class="  text-secondary fw-bold center">
                                        <span>Inicio</span>
                                    </a>
                                </div>

                                <div class="mt-2">

                                    <a href="javascript:void(0)" [routerLink]="['financial']" class=" text-secondary fw-bold center">
                                        <span>  Mis compras</span>
                                    </a>
                                </div>

                                <div class="mt-2">
                                    <a href="javascript:void(0)" [routerLink]="['payments']" class="text-secondary fw-bold center">
                                        <span>  Mis pagos</span>
                                    </a>
                                </div>


                                <hr>
                            </div>

                            <a href="javascript:void(0)" (click)="logout()" class="text-primary fw-bold center">
                                <span>Salir</span>
                            </a>

                        </div>

                    </div>
                </div>


            </div>
        </div>


    </nav>

</div>