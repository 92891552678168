import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';

import {map,tap} from 'rxjs/operators';
import { AuthService } from '../services/auth/auth.service';
@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  constructor(
    private router: Router,
    public _auth: AuthService,

  ) {}




  private modifyBody = async (body: any) => {
debugger
    try {
      const token: any = localStorage.getItem('x-token');
      if(!body?.x_token){
        let resp = await this._auth.refresh_token({"x_token":token});

        if(resp?.x_token){
          localStorage.setItem('x-token',resp?.x_token)
        }
      }
    } catch (error:any) {

    }

}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
    const token: any = localStorage.getItem('x-token');
    let request = req;

    if (token) {
      request = req.clone({
        setHeaders: {
        // authorization: `Bearer ${ token }`
           authorization: `${ token }`

        }
      });
    }


  
    return next.handle(request)
    .pipe(map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        if (this._auth.isAuth) {
           this.modifyBody(event.body);
        }
      }
      return event;
  })).pipe(
    catchError((resp: HttpErrorResponse) => {
      if (resp.status === 401) {
        this.router.navigate([environment.logoutRoute], {});
      }

      return throwError(() => resp);

    })
  )
  ;

/*
  

    return next.handle(request).pipe(
      catchError((resp: HttpErrorResponse) => {
        if (resp.status === 401) {
          this.router.navigate([environment.logoutRoute], {});
        }

        return throwError(() => resp);

      })
    );*/
  }

}
